import React from "react"
import { useContext } from "react"
import Wrapper from "../shared/wrapper"
import ZiptiButton from "../shared/zipti_button"
import ZiptiText from "../shared/zipti_text"
import { ApiContext, UserContext } from "../user_wrapper"
import { getIdFromUrl } from "../../services/utils"
import FacebookLoginWrapper from "../facebook_login_wrapper"
import ZiptiContainer from "../shared/zipti_container"
import { navigate } from "gatsby"
import { useEffect } from "react"
import { Box } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/react"

const ExpertInvite: React.FC = props => {
  const apiClient = useContext(ApiContext)
  const user = useContext(UserContext)

  const expertInviteUseEffect = async () => {
    if (user.data?.is_anonymous) {
      return
    }
    if (user.data?.account?.is_expert) {
      navigate("/edit_profile")
      return
    }
    const confirmationCode = getIdFromUrl("expert_invite")
    const res = await apiClient.activateExpertAccount(
      user.data?.account?.account_id,
      confirmationCode
    )
    if (res.error) {
      // TODO: This should be handled better...
      alert("There was a problem setting your profile to expert.")
      navigate("/home")
    }
    console.log(res)
    await user.resetUser()
    navigate("/edit_profile")
  }

  useEffect(() => {
    expertInviteUseEffect()
  }, [user.data?.is_anonymous])

  const renderLogin = () => {
    return (
      <Box>
        <FacebookLoginWrapper
          onLogin={() => {
            if (user.data?.account?.is_expert) navigate("/edit_profile")
          }}
        >
          <ZiptiButton p="1rem" mt={{ base: "2rem", md: "4rem" }}>
            Continue with Facebook
          </ZiptiButton>
        </FacebookLoginWrapper>
      </Box>
    )
  }

  const renderActivationPage = () => {
    return (
      <Box>
        <ZiptiText m="4rem">Setting you up as an expert...</ZiptiText>
        <Spinner color="#4329ae" />
      </Box>
    )
  }

  return (
    <Wrapper textAlign="center">
      {user.data?.is_anonymous ? renderLogin() : renderActivationPage()}
    </Wrapper>
  )
}

export default ExpertInvite
